import React from "react";
import format from "date-fns/format";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { AccountCircleIcon } from "../../../Styles/Icons/DesignSystem/AccountCircleIcon";
import { VisibilityIcon } from "../../../Styles/Icons/DesignSystem/VisibilityIcon";
import { FallIcon } from "../../../Styles/Icons/DesignSystem";
import { LocationOnIcon } from "../../../Styles/Icons/DesignSystem/LocationOnIcon";
import { ConditionsNewIcon } from "../../../Styles/Icons/DesignSystem/ConditionsNewIcon";

const FallLogCard = ({ fallLog }) => {
  const staff = useSelector((state) => state.staff);

  const date = new Date(fallLog.timestamp);

  const { observation, responder, location, summary, causes, outcomes } =
    extractAndFormatData(fallLog, staff);

  return (
    <div className={styles.content}>
      <p>
        <span className={styles.fall_span}>Fall:</span>{" "}
        <span className={styles.fall_time_span}>
          {`${format(date, "HH:mm")} on ${format(date, "dd/MM/yy")}`}
        </span>
      </p>
      <div className={styles.fall_log_content}>
        <div
          className={classNames(
            styles.fall_log_content_box,
            styles.fall_log_content_box_start
          )}
        >
          {responder ? (
            <div className={styles.information_container}>
              <AccountCircleIcon />
              <p className={styles.label}>
                Responder:{" "}
                <span className={styles.information}>{responder}</span>
              </p>
            </div>
          ) : null}
          {observation ? (
            <div className={styles.information_container}>
              <VisibilityIcon />
              <p className={styles.label}>
                Was this fall witnessed:{" "}
                <span className={styles.information}>{observation}</span>
              </p>
            </div>
          ) : null}
          {causes ? (
            <div className={styles.information_container}>
              <FallIcon />
              <p className={styles.label}>
                Cause of the fall:{" "}
                <span className={styles.information}>{causes}</span>
              </p>
            </div>
          ) : null}
          {outcomes ? (
            <div className={styles.information_container}>
              <ConditionsNewIcon />
              <p className={styles.label}>
                Initial outcome of fall:{" "}
                <span className={styles.information}>{outcomes}</span>
              </p>
            </div>
          ) : null}

          <div className={styles.information_container}>
            <LocationOnIcon />
            <p className={styles.label}>
              Fall location:{" "}
              <span className={styles.information}>{location}</span>
            </p>
          </div>
        </div>
        <div className={styles.fall_log_content_box}>
          <p className={styles.label}>Summary of the incident: </p>
          <p className={styles.information}>{summary}</p>
        </div>
      </div>
    </div>
  );
};

export default FallLogCard;

// TODO: make this generic for all fall status types?
export const extractAndFormatData = (fallLog, staff) => {
  const acknowledgedEventUser = fallLog.events.find(
    (event) => event.status === "acknowledged"
  ).userId;
  const responder = staff.find((user) => user.id === acknowledgedEventUser);

  const resolvedMetaData =
    fallLog.events.find((event) => event.status === "attended")?.metadata ||
    fallLog.events[fallLog.events.length - 1].metadata;

  const causes = [
    resolvedMetaData.cause.slipped && "Slipped/tripped",
    resolvedMetaData.cause.environmentalHazard && "Environmental hazard",
    resolvedMetaData.cause.lossOfBalance && "Loss of balance",
    resolvedMetaData.cause.medicalEvent && "Medical event",
    resolvedMetaData.cause.other,
  ]
    .filter(Boolean)
    .join(", ");

  const outcomes = [
    resolvedMetaData.outcome.familyContacted && "Family to be contacted",
    resolvedMetaData.outcome.observationRequired &&
      "Observation required (monitoring for changes)",
    resolvedMetaData.outcome.ambulanceCalled &&
      "Ambulance called (potential serious injury)",
    resolvedMetaData.outcome.doctorContacted && "Doctor to be contacted",
    resolvedMetaData.outcome.minorInjury && "Bruising / Minor injury",
    resolvedMetaData.outcome.noInjury && "No injury / Stable condition",
    resolvedMetaData.outcome.painDiscomfort &&
      "Pain or discomfort (no serious injury)",
    resolvedMetaData.outcome.other,
  ]
    .filter(Boolean)
    .join(", ");

  return {
    responder: responder ? `${responder.forenames} ${responder.surname}` : null,
    observation: resolvedMetaData.sawFallOccur
      ? "Yes"
      : resolvedMetaData.didNotSeeFallOccur
        ? "No"
        : null,
    causes,
    outcomes,
    location: fallLog.location,
    summary:
      resolvedMetaData.summary && resolvedMetaData.summary !== ""
        ? resolvedMetaData.summary
        : "No summary documented",
  };
};
