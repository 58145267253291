import React from "react";
import styles from "./styles.module.scss"; // generic form styles
import { Form, Field } from "react-final-form";
import Checkbox from "../../Components/Checkbox";
import LilliButton from "../../Components/DesignSystemComponents/LilliButton/LilliButton";
import LilliSelect from "../../Components/LilliDesignSystem/LilliSelect";
const mapValuesToMetadata = (values) => {
  const filterTruthyValues = (fields) => {
    return Object.entries(fields).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value; // Include only truthy values
      }
      return acc;
    }, {});
  };

  return {
    outcome: filterTruthyValues({
      familyContacted: values.familyContacted || false,
      observationRequired: values.observationRequired || false,
      ambulanceCalled: values.ambulanceRequired || false,
      doctorContacted: values.doctorContacted || false,
      minorInjury: values.bruising || false,
      stable: values.noInjury || false,
      painDiscomfort: values.pain || false,
      other: values.otherOutcome ? values.otherOutcomeText || "" : "", // handle optional text field
      condition: values.condition?.value || false,
    }),
    falseAlarm: values.falseAlarm,
    summary: values.summary || "",
    version: 1,
  };
};

const FallCQCCheckForm = ({ onSubmit, closeModal }) => {
  const handleFallResolveForm = async (values) => {
    const transformedData = mapValuesToMetadata(values);

    await onSubmit(transformedData);
  };

  const options = [
    { label: "Improved", value: "improved" },
    { label: "Deteriorated", value: "deteriorated" },
    { label: "No change", value: "noChange" },
    { label: "Not sure", value: "notSure" },
  ];
  return (
    <Form
      onSubmit={(values) => handleFallResolveForm(values)}
      validate={(values) => {
        const errors = {};
        if (!values.followUpCompleted && !values.followUpNotCompleted) {
          errors.followUpCompleted = "Please select an option";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, valid, form }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          {/* Follow up section */}
          <div className={styles.section}>
            <h2 className={styles.label}>Has this follow-up been completed?</h2>
            <Field name="followUpCompleted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox
                    input={{
                      ...input,
                      onChange: (e) => {
                        input.onChange(e); // Handle normal change
                        if (e.target.checked) {
                          form.change("followUpNotCompleted", false); // Uncheck the other field
                        }
                      },
                    }}
                  />
                  <span>Yes</span>
                </label>
              )}
            </Field>
            <Field name="followUpNotCompleted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox
                    input={{
                      ...input,
                      onChange: (e) => {
                        input.onChange(e); // Handle normal change
                        if (e.target.checked) {
                          form.change("followUpCompleted", false); // Uncheck the other field
                        }
                      },
                    }}
                  />
                  <span>No</span>
                </label>
              )}
            </Field>
          </div>

          <div className={styles.section}>
            <h2 className={styles.label}>
              Has their condition improved or deteriorated since falling?
            </h2>
            <Field name="condition" type="select">
              {({ input }) => (
                <label className={styles.field_check}>
                  <LilliSelect
                    withinModal
                    value={[{ label: null, value: null }]}
                    placeholder="Select"
                    options={options}
                    {...input}
                    width={130}
                  />
                </label>
              )}
            </Field>
          </div>
          {/* OUTCOME SECTION */}
          <div className={styles.section}>
            <h2 className={styles.label}>
              Outcome of first follow-up (Select all that apply)
            </h2>
            <Field name="noInjury" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>No injury / Stable condition</span>
                </label>
              )}
            </Field>
            <Field name="bruising" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Bruising / Minor injury</span>
                </label>
              )}
            </Field>
            <Field name="pain" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Pain or discomfort (no serious injury)</span>
                </label>
              )}
            </Field>
            <Field name="observationRequired" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Observation required (monitoring for changes)</span>
                </label>
              )}
            </Field>
            <Field name="ambulanceRequired" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Ambulance Called (potential serious injury)</span>
                </label>
              )}
            </Field>
            <Field name="doctorContacted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Doctor to be contacted</span>
                </label>
              )}
            </Field>
            <Field name="familyContacted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Family to be contacted</span>
                </label>
              )}
            </Field>
            <Field name="otherOutcome" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Other</span>
                </label>
              )}
            </Field>
            {/* Conditionally Rendered Text Input */}
            <Field name="otherOutcome" subscription={{ value: true }}>
              {({ input: { value } }) =>
                value ? (
                  <Field name="otherOutcomeText">
                    {({ input }) => (
                      <div>
                        <input
                          {...input}
                          type="text"
                          className={styles.input}
                          placeholder="Please specify..."
                        />
                      </div>
                    )}
                  </Field>
                ) : null
              }
            </Field>
          </div>
          {/* SUMMARY SECTION */}
          <div>
            <Field name="summary" type="freeText" initialValue={""}>
              {({ input }) => {
                return (
                  <>
                    <h2 className={styles.text_area_label}>
                      Summary of the first follow-up (add any other details)
                    </h2>
                    <textarea
                      data-private
                      name="monitoring"
                      placeholder="Please specify..."
                      maxLength={601}
                      className={styles.text_area}
                      {...input}
                    />
                  </>
                );
              }}
            </Field>
          </div>
          <div className={styles.buttons}>
            <LilliButton variant="secondary" onClick={closeModal}>
              Cancel
            </LilliButton>
            <LilliButton
              onClick={handleSubmit}
              disabled={submitting || !valid}
              type="submit"
            >
              Submit
            </LilliButton>
          </div>
        </form>
      )}
    ></Form>
  );
};

export default FallCQCCheckForm;
