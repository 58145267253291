import React from "react";
import Page from "../../Components/Page";
import LilliButton from "../../Components/DesignSystemComponents/LilliButton/LilliButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FallLogCard from "../../Components/Falls/FallLogCard";
import { BackIcon } from "../../Styles/Icons/DesignSystem";
import styles from "./styles.module.scss";
import format from "date-fns/format";
import Skeleton from "../../Components/Skeleton";

const FallsRoom = ({ loading }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const serviceUser = useSelector((state) =>
    state.serviceUsers.find((su) => su.id.toString() === id)
  );
  const fallLog = useSelector((state) => state.serviceUsersData)[id];

  const resolved = fallLog?.falls
    ?.filter(
      (fall) =>
        fall?.currentStatus === "resolved" &&
        !fall?.events[fall?.events.length - 1].metadata?.falseAlarm
    )
    .sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp));

  return (
    <Page>
      <div className={styles.header}>
        <LilliButton
          variant="secondary"
          leftIcon={<BackIcon />}
          onClick={() => navigate("/")}
        >
          Back to dashboard
        </LilliButton>
        <h1 className={styles.heading_text}>{serviceUser?.userstring}</h1>
        <p className={styles.heading_subtext}>
          Last fall:{" "}
          {resolved?.[0]?.timestamp ? (
            format(new Date(resolved?.[0]?.timestamp), "dd/MM/yy")
          ) : (
            <span>No falls detected</span>
          )}
        </p>
      </div>
      <div className={styles.content}>
        <h2 className={styles.content_header}>Falls log</h2>

        {loading ? (
          <Skeleton height="100px" />
        ) : resolved?.length > 0 ? (
          resolved.map((fall) => <FallLogCard fallLog={fall} key={fall.id} />)
        ) : (
          <div className={styles.no_falls}>
            <h3 className={styles.no_falls_text}>
              No falls logged for this room
            </h3>
          </div>
        )}
      </div>
    </Page>
  );
};

export default FallsRoom;
