import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
// Components
import Page from "../../Components/Page";
import FallsDashboardHeader from "../../Components/FallsDashboardHeader";
import FallCard from "../../Components/FallCard";
import Line from "../../Components/Line";
import FallDetectedModal from "../../Components/FallDetectedModal";
import FallResolutionModal from "../../Components/FallResolutionModal";
// Hooks
import { useFade } from "../../Services/Hooks";
import { postHubFallEvent } from "@intelligentlilli/api-layer";
// Utils
import LogRocket from "logrocket";
import { groupFallsData } from "../../Services/Utils/";
import theme from "../../Styles/theme.scss";
import { SERVER } from "../../Services/config";
import { useDispatch } from "react-redux";
import { updateServiceUserFalls } from "../../State/slices/serviceUsersData-slice";
import { useNavigate } from "react-router-dom";
import FallCQCCheckModal from "../../Components/Falls/FallCQCCheckModal";

const FallsDashboard = ({ error: getError, lastFetched }) => {
  const user = useSelector((state) => state.user);
  // const serviceUsersData = suData; // TO DO FALLS: remove when pushing to PROD
  const serviceUsersData = useSelector((state) => state.serviceUsersData);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fallDetectedModalData, setFallDetectedModalData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [error, setError] = useState(null);
  const [loadingRooms, setLoadingRooms] = useState([]);

  useEffect(() => {
    if (user.key && user.forenames) {
      LogRocket.identify(user.key, {
        name: user.forenames,
        organisationId: user.organisationId,
        isAdmin: user.roles?.includes("admin"),
        isManager:
          user.roles?.includes("manager") && !user.roles?.includes("admin"),
        isStaff:
          user.roles?.includes("user") &&
          !user.roles?.includes("manager") &&
          !user.roles?.includes("admin"),
        isInstallerOnly:
          user.roles?.includes("installer") &&
          !user.roles?.includes("user") &&
          !user.roles?.includes("manager") &&
          !user.roles?.includes("admin"),
        hasInstallerPermissions:
          user.roles?.includes("installer") && !user.roles?.includes("admin"),
      });
    }
  }, [user.key, user.hubs, user.organisationId, user.roles, user.forenames]);

  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  const { liveFalls, recentFalls, otherUsers } = groupFallsData(
    serviceUsersData,
    serviceUsers
  );

  const acknowledgeHandler = async (fallDetectedModalData, type) => {
    setLoadingRooms((prev) => {
      if (!prev.includes(fallDetectedModalData.room)) {
        return [...prev, fallDetectedModalData.room];
      } else {
        return prev;
      }
    });
    setError(null);
    try {
      const updateFallResponse = await postHubFallEvent({
        server: SERVER,
        hubId: fallDetectedModalData.serviceUserId,
        fallId: fallDetectedModalData.id,
        fallEvent: { status: "acknowledged" },
        origin: "web",
      });
      if (updateFallResponse.ok && updateFallResponse?.body) {
        dispatch(
          updateServiceUserFalls({
            hubId: fallDetectedModalData.serviceUserId,
            fallId: fallDetectedModalData.id,
            status: "acknowledged",
            event: updateFallResponse.body,
          })
        );
      }
      type === "falseAlarm"
        ? falseAlarmHandler(fallDetectedModalData)
        : setLoadingRooms((prev) =>
            prev.filter((room) => room !== fallDetectedModalData.room)
          );
      if (!updateFallResponse.ok) {
        setError("Failed to update fall event");
      }
    } catch (error) {
      setLoadingRooms((prev) =>
        prev.filter((room) => room !== fallDetectedModalData.room)
      );
      setError("Failed to update fall event");
      console.error("Error while updating fall event", error);
    }
  };

  const falseAlarmHandler = async (fallDetectedModalData, metaData = {}) => {
    setShowAnimation(false);
    setLoadingRooms((prev) => {
      if (!prev.includes(fallDetectedModalData.room)) {
        return [...prev, fallDetectedModalData.room];
      } else {
        return prev;
      }
    });
    setError(null);
    try {
      const updateFallResponse = await postHubFallEvent({
        server: SERVER,
        hubId: fallDetectedModalData.serviceUserId,
        fallId: fallDetectedModalData.id,
        fallEvent: {
          status: "false_alarm",
          metadata: metaData,
        },
        origin: "web",
      });

      if (updateFallResponse.ok && updateFallResponse?.body) {
        dispatch(
          updateServiceUserFalls({
            hubId: fallDetectedModalData.serviceUserId,
            status: "false_alarm",
            fallId: fallDetectedModalData.id,
            event: updateFallResponse.body,
          })
        );
      } else {
        setError("Failed to update fall event");
      }

      setLoadingRooms((prev) =>
        prev.filter((room) => room !== fallDetectedModalData.room)
      );
    } catch (error) {
      setLoadingRooms((prev) =>
        prev.filter((room) => room !== fallDetectedModalData.room)
      );
      setError("Failed to update fall event");
      console.error("Error while updating fall event", error);
    }
  };

  const resolutionHandler = async (
    fallDetectedModalData,
    metaData,
    status,
    resolveOnSuccess
  ) => {
    setShowAnimation(false);
    setLoadingRooms((prev) => {
      if (!prev.includes(fallDetectedModalData.room)) {
        return [...prev, fallDetectedModalData.room];
      } else {
        return prev;
      }
    });
    setError(null);
    try {
      const updateFallResponse = await postHubFallEvent({
        server: SERVER,
        hubId: fallDetectedModalData.serviceUserId,
        fallId: fallDetectedModalData.id,
        fallEvent: { status: status, metadata: metaData },
        origin: "web",
      });

      if (updateFallResponse.ok && updateFallResponse?.body) {
        if (resolveOnSuccess) {
          const resolutionFallResponse = await postHubFallEvent({
            server: SERVER,
            hubId: fallDetectedModalData.serviceUserId,
            fallId: fallDetectedModalData.id,
            fallEvent: { status: "resolved" },
            origin: "web",
          });
          if (resolutionFallResponse.ok && resolutionFallResponse?.body) {
            dispatch(
              updateServiceUserFalls({
                hubId: fallDetectedModalData.serviceUserId,
                fallId: fallDetectedModalData.id,
                status: "resolved",
                event: updateFallResponse.body,
              })
            );
          }
        } else {
          dispatch(
            updateServiceUserFalls({
              hubId: fallDetectedModalData.serviceUserId,
              fallId: fallDetectedModalData.id,
              status: status,
              event: updateFallResponse.body,
            })
          );
        }
      }
      setLoadingRooms((prev) =>
        prev.filter((room) => room !== fallDetectedModalData.room)
      );
      if (!updateFallResponse.ok) {
        setError("Failed to update fall");
      }
    } catch (error) {
      console.error("Error resolving fall", error);
      setError("Failed to update fall");
      setLoadingRooms((prev) =>
        prev.filter((room) => room !== fallDetectedModalData.room)
      );
    }
  };

  const handleFallCardClick = (fall) => {
    console.log("fall", fall);
    switch (fall.currentStatus) {
      case "detected":
        setFallDetectedModalData(fall);
        setShowAnimation(true);
        setModalType("respond");
        break;
      case "acknowledged":
        setFallDetectedModalData(fall);
        setShowAnimation(true);
        setModalType("resolve");
        break;
      case "check_required":
        setFallDetectedModalData(fall);
        setShowAnimation(true);
        setModalType("cqc");
        break;
      default:
        navigate(`/rooms/${fall.serviceUserId}`);
        break;
    }
  };

  return (
    <Page>
      <div className={styles.content}>
        <FallsDashboardHeader
          error={error}
          lastFetched={lastFetched}
          getError={getError}
        />

        <h2>Live falls</h2>
        {liveFalls.length === 0 ? (
          <div className={styles.no_falls}>
            <h3>No current falls</h3>
          </div>
        ) : (
          <div className={styles.section}>
            {liveFalls.map((fall, index) => (
              <FallCard
                key={index}
                fall={fall}
                onClick={() => handleFallCardClick(fall)}
                loading={loadingRooms.includes(fall.room)}
              />
            ))}
          </div>
        )}
        {recentFalls.length > 0 && (
          <>
            <Line className={styles.line} />
            <h2>Falls in the past 72 hrs</h2>
            <div className={styles.section}>
              {recentFalls.map((fall, index) => (
                <FallCard
                  key={index}
                  fall={fall}
                  onClick={() => handleFallCardClick(fall)}
                  loading={loadingRooms.includes(fall.room)}
                />
              ))}
            </div>
          </>
        )}
        <Line className={styles.line} />
        <h2>All other users</h2>
        <div className={styles.section}>
          {otherUsers.map((fall, index) => (
            <FallCard
              key={index}
              fall={fall}
              onClick={() => handleFallCardClick(fall)}
              loading={loadingRooms.includes(fall.room)}
            />
          ))}
        </div>
      </div>
      {/* Modal: Fall detected */}
      {isVisible && modalType === "respond" && (
        <FallDetectedModal
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          fallDetectedModalData={fallDetectedModalData}
          acknowledgeHandler={acknowledgeHandler}
        />
      )}
      {/* Modal: Fall detected */}
      {isVisible && modalType === "resolve" && (
        <FallResolutionModal
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          fallDetectedModalData={fallDetectedModalData}
          resolutionHandler={resolutionHandler}
          falseAlarmHandler={falseAlarmHandler}
        />
      )}
      {/* Modal: CQC Check*/}
      {isVisible && modalType === "cqc" && (
        <FallCQCCheckModal
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          fallDetectedModalData={fallDetectedModalData}
          resolutionHandler={resolutionHandler}
        />
      )}
      <div className={styles.footer}>
        {" "}
        <a
          href="mailto: support@intelligentlilli.com"
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.secondary3 }}
        >
          support@intelligentlilli.com
        </a>
      </div>
    </Page>
  );
};

export default FallsDashboard;
