import React from "react";
import theme from "../../theme.scss";

export const AssignmentTurnedInIcon = ({ width, height, colour }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      width={width ?? "24px"}
      height={height ?? "25px"}
    >
      <path
        d="M10.6 16.53L17.65 9.47998L16.25 8.07998L10.6 13.73L7.75 10.88L6.35 12.28L10.6 16.53ZM5 21.48C4.45 21.48 3.97917 21.2841 3.5875 20.8925C3.19583 20.5008 3 20.03 3 19.48V5.47998C3 4.92998 3.19583 4.45915 3.5875 4.06748C3.97917 3.67581 4.45 3.47998 5 3.47998H9.2C9.41667 2.87998 9.77917 2.39665 10.2875 2.02998C10.7958 1.66331 11.3667 1.47998 12 1.47998C12.6333 1.47998 13.2042 1.66331 13.7125 2.02998C14.2208 2.39665 14.5833 2.87998 14.8 3.47998H19C19.55 3.47998 20.0208 3.67581 20.4125 4.06748C20.8042 4.45915 21 4.92998 21 5.47998V19.48C21 20.03 20.8042 20.5008 20.4125 20.8925C20.0208 21.2841 19.55 21.48 19 21.48H5ZM5 19.48H19V5.47998H5V19.48ZM12 4.72998C12.2167 4.72998 12.3958 4.65915 12.5375 4.51748C12.6792 4.37581 12.75 4.19665 12.75 3.97998C12.75 3.76331 12.6792 3.58415 12.5375 3.44248C12.3958 3.30081 12.2167 3.22998 12 3.22998C11.7833 3.22998 11.6042 3.30081 11.4625 3.44248C11.3208 3.58415 11.25 3.76331 11.25 3.97998C11.25 4.19665 11.3208 4.37581 11.4625 4.51748C11.6042 4.65915 11.7833 4.72998 12 4.72998Z"
        fill={colour ?? theme.neutral7}
      />
    </svg>
  );
};
