import React, { useState, useEffect } from "react";
import NewStyleModal from "../../Components/NewStyleModal";
import { intervalToDuration } from "date-fns";
import styles from "./styles.module.scss";
import LilliButton from "../DesignSystemComponents/LilliButton/LilliButton";
import { AttendingIcon } from "../../Styles/Icons/DesignSystem";

const FallDetectedModal = ({
  setShowAnimation,
  showAnimation,
  fallDetectedModalData,
  acknowledgeHandler,
}) => {
  const startTime =
    fallDetectedModalData.events[fallDetectedModalData.events.length - 1]
      .timestamp;
  const [timeElapsed, setTimeElapsed] = useState("");
  const [falseAlarm, setFalseAlarm] = useState(false);

  useEffect(() => {
    const updateTimer = () => {
      const duration = intervalToDuration({
        start: new Date(startTime),
        end: new Date(),
      });
      if (duration.days > 0) {
        setTimeElapsed(
          `${duration.days} day${duration.days > 1 ? "s" : ""} ago`
        );
        return;
      }
      const formattedTime = [
        String(duration.hours).padStart(2, "0"),
        String(duration.minutes).padStart(2, "0"),
        String(duration.seconds).padStart(2, "0"),
      ].join(":");

      setTimeElapsed(formattedTime);
    };

    // Update every second
    updateTimer(); // Update immediately on mount
    const timerId = setInterval(updateTimer, 1000);

    // Cleanup timer on unmount
    return () => clearInterval(timerId);
  }, [startTime]);

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => setShowAnimation(false)}
      useFade={true}
      showAnimation={showAnimation}
    >
      <div className={styles.container}>
        {falseAlarm && (
          <>
            <h1>Set as false alarm?</h1>
            <p>
              You've selected 'False alarm'. This means no fall occurred, and no
              further action will be recorded for this incident.
            </p>
            <div className={styles.spacer} />
            <p>Are you sure you want to proceed?</p>
            <div className={styles.spacer} />
            <div className={styles.buttons}>
              <LilliButton
                variant="secondary"
                onClick={() => setFalseAlarm(false)}
              >
                Go back
              </LilliButton>
              <LilliButton
                onClick={() => {
                  acknowledgeHandler(fallDetectedModalData, "falseAlarm");
                  setShowAnimation(false);
                }}
              >
                Set as false alarm
              </LilliButton>
              <div className={styles.spacer} />
            </div>
            <div className={styles.spacer} />
          </>
        )}
        {!falseAlarm && (
          <>
            <h1>Fall Detected</h1>
            <h2>{fallDetectedModalData.room}</h2>
            <div className={styles.spacer} />
            <p>Time since fall:</p>
            <h2>{timeElapsed}</h2>
            <div className={styles.spacer} />
            <LilliButton
              onClick={() => {
                setShowAnimation(false);
                acknowledgeHandler(fallDetectedModalData);
              }}
              leftIcon={<AttendingIcon />}
            >
              On my way
            </LilliButton>
            <div className={styles.spacer} />
            <div className={styles.spacer} />
            <LilliButton
              variant="dangerSecondary"
              onClick={() => setFalseAlarm(true)}
            >
              False alarm
            </LilliButton>
            <div className={styles.spacer} />
          </>
        )}
      </div>
    </NewStyleModal>
  );
};

export default FallDetectedModal;
