import React from "react";
import styles from "./styles.module.scss";
import {
  ArrowCircleRightIcon,
  AttendingIcon,
  BathroomIcon,
  BedIcon,
  FallIcon,
  AssignmentTurnedInIcon,
} from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";
import { format, isSameDay } from "date-fns";
import classNames from "classnames";
import { isLessThanEqualTo7DaysOld } from "../../Services/Utils";
import { useSelector } from "react-redux";
import FallCardSkeleton from "./FallCardSkeleton";

const FallCard = ({ fall, onClick, loading }) => {
  const [hovering, setHovering] = React.useState(false);
  const [pressing, setPressing] = React.useState(false);
  const staff = useSelector((state) => state.staff);
  if (loading) {
    return <FallCardSkeleton room={fall.room} />;
  }
  const neverFallen = fall.currentStatus === "never-fallen";

  const detected = fall.currentStatus === "detected";
  const acknowledged = fall.currentStatus === "acknowledged";
  // We only show the check required UI once the 30 minutes have passed i.e. the future flag is false
  const checkRequired =
    fall.currentStatus === "check_required" &&
    !fall.events[fall.events.length - 1].future;
  // In addition to the above checks we know it's the second follow up check if the first check has been completed
  const check2Required =
    fall.currentStatus === "check_required" &&
    !!fall.events.find((event) => event.status === "check_completed") &&
    !fall.events[fall.events.length - 1].future;

  const fallEvent = fall.events?.filter((i) => i.status === "detected");
  const timeOfFall = fallEvent?.[0]?.timestamp;
  const lastEvent = fall.events?.[fall.events.length - 1];
  const staffMemberAtended = staff.find((i) => i.id === lastEvent?.userId);

  const getCardColour = (fall, room) => {
    if (
      fall.location !== room ||
      !fall.location ||
      fall.currentStatus === "resolved"
    ) {
      return theme.neutral2;
    } else if (fall.currentStatus === "detected") {
      return theme.risk3;
    } else {
      return theme.expected1;
    }
  };

  const getFallTime = (ts) => {
    const date = new Date(ts);
    if (isSameDay(date, new Date())) {
      return `${format(new Date(date), "H:mm")} Today`;
    } else if (!isLessThanEqualTo7DaysOld(date)) {
      return `${format(new Date(date), "H:mm • dd/MM/yy")}`;
    } else {
      return `${format(new Date(date), "H:mm")} on ${format(new Date(date), "d MMM")}`;
    }
  };

  return (
    <button
      className={classNames(styles.card, {
        [styles.card_detected]: detected,
        [styles.card_acknowledged]: acknowledged || checkRequired,
      })}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      // onPress style change
      onMouseDown={() => setPressing(true)}
      onMouseUp={() => setPressing(false)}
      onClick={onClick}
    >
      <div className={styles.row}>
        <h3>{fall.room}</h3>
        {detected && (
          <div className={styles.fall_icon}>
            <FallIcon width={20} />
            <div>Fall detected</div>
          </div>
        )}
        {acknowledged && (
          <div className={styles.acknowledged_icon}>
            <AttendingIcon />
            <div>{staffMemberAtended?.forenames || ""} Attending</div>
          </div>
        )}
        {checkRequired && !check2Required && (
          <div className={styles.acknowledged_icon}>
            <AssignmentTurnedInIcon />
            <div>1st follow up</div>
          </div>
        )}
        {check2Required && (
          <div className={styles.acknowledged_icon}>
            <AssignmentTurnedInIcon />
            <div>2nd follow up</div>
          </div>
        )}
      </div>
      <div className={styles.row}>
        <div
          className={styles.sensor}
          style={{ backgroundColor: getCardColour(fall, "Bedroom") }}
        >
          <div>Bedroom</div>
          <BedIcon />
          <h4>{fall.location === "Bedroom" ? "Fall" : ""}</h4>
        </div>
        <div
          className={classNames(styles.sensor, {
            [styles.fall_in_progress]: detected,
            [styles.fall_attended]: acknowledged,
          })}
          style={{ backgroundColor: getCardColour(fall, "Bathroom") }}
        >
          <div>Bathroom</div>
          <BathroomIcon />
          <h4>{fall.location === "Bathroom" ? "Fall" : ""}</h4>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.row_element}>
          {!neverFallen && <FallIcon width={20} />}
          <p className={styles.time_of_fall}>
            {!neverFallen && getFallTime(timeOfFall)}
          </p>
        </div>
        {onClick && (
          <div
            className={classNames({
              [styles.action_hover]: hovering,
              [styles.action]: !hovering,
              [styles.action_pressing]: pressing,
              [styles.action]: !pressing,
            })}
          >
            {detected ? "Take action" : "View details"}
            <ArrowCircleRightIcon width={16} />
          </div>
        )}
      </div>
    </button>
  );
};

export default FallCard;
