import React, { useState, useEffect } from "react";
import NewStyleModal from "../../NewStyleModal";
import { format, isAfter } from "date-fns";
import styles from "./styles.module.scss";
import FallCQCCheckForm from "../../../Forms/FallCQCCheckForm";
import { intervalToDuration } from "date-fns";
import Line from "../../Line";
import {
  ConditionsNewIcon,
  ListAltCheckIcon,
  UpdateIcon,
  ScheduleIcon,
} from "../../../Styles/Icons/DesignSystem";

const FallCQCCheckModal = ({
  setShowAnimation,
  showAnimation,
  fallDetectedModalData,
  resolutionHandler,
}) => {
  const startTime = fallDetectedModalData?.timestamp;

  const [timeElapsed, setTimeElapsed] = useState("");

  const isSecondFollowUp = fallDetectedModalData.events.find(
    (event) => event.status === "check_completed"
  );
  const checkUpTime = new Date(
    fallDetectedModalData.events[
      fallDetectedModalData.events.length - 1
    ].timestamp
  );
  const checkIsDue = isAfter(new Date(), checkUpTime);

  const resolvedMetaData = fallDetectedModalData.events.find(
    (event) => event.status === "attended"
  ).metadata;

  //TODO: extract these variables into 1 source of truth
  const outcomes = [
    resolvedMetaData.outcome.familyContacted && "Family to be contacted",
    resolvedMetaData.outcome.observationRequired &&
      "Observation required (monitoring for changes)",
    resolvedMetaData.outcome.ambulanceCalled &&
      "Ambulance called (potential serious injury)",
    resolvedMetaData.outcome.doctorContacted && "Doctor to be contacted",
    resolvedMetaData.outcome.minorInjury && "Bruising / Minor injury",
    resolvedMetaData.outcome.noInjury && "No injury / Stable condition",
    resolvedMetaData.outcome.painDiscomfort &&
      "Pain or discomfort (no serious injury)",
    resolvedMetaData.outcome.other,
  ]
    .filter(Boolean)
    .join(", ");

  useEffect(() => {
    const updateTimer = () => {
      const duration = intervalToDuration({
        start: new Date(startTime),
        end: new Date(),
      });
      if (duration.days > 0) {
        setTimeElapsed(
          `${duration.days} day${duration.days > 1 ? "s" : ""} ago`
        );
        return;
      }
      const formattedTime = [
        String(duration.hours).padStart(2, "0"),
        String(duration.minutes).padStart(2, "0"),
        String(duration.seconds).padStart(2, "0"),
      ].join(":");

      setTimeElapsed(formattedTime);
    };

    // Update every second
    updateTimer(); // Update immediately on mount
    const timerId = setInterval(updateTimer, 1000);

    // Cleanup timer on unmount
    return () => clearInterval(timerId);
  }, [startTime]);

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => {
        setShowAnimation(false);
      }}
      useFade={true}
      showAnimation={showAnimation}
    >
      <div className={styles.heading}>
        <h1 className={styles.header}>
          {fallDetectedModalData?.room}: Follow-up{" "}
          {isSecondFollowUp ? "2 (after 1 hour)" : "1 (after 30 minutes)"}
        </h1>
        <p className={styles.subtext}>
          We recommend two follow-up checks after a recent fall. Assess the
          resident to check for any changes in their condition and record your
          findings below.
        </p>
        <div className={styles.heading_section}>
          <div className={styles.information_container}>
            <ScheduleIcon />
            <p className={styles.label}>
              Time of fall:{" "}
              <span className={styles.information}>
                {format(new Date(startTime), "HH:mm")}
              </span>
            </p>
          </div>
          <div className={styles.information_container}>
            <UpdateIcon />
            <p className={styles.label}>
              Time since fall:{" "}
              <span className={styles.information}>{timeElapsed}</span>
            </p>
          </div>
          {outcomes ? (
            <div className={styles.information_container}>
              <ConditionsNewIcon />
              <p className={styles.label}>
                Initial outcome of fall:{" "}
                <span className={styles.information}>{outcomes}</span>
              </p>
            </div>
          ) : null}
          {isSecondFollowUp && (
            <div className={styles.information_container}>
              <ListAltCheckIcon />
              <p className={styles.label}>
                Condition status of 1st follow up:{" "}
                <span className={styles.information}>
                  {isSecondFollowUp.outcome?.condition || "Not recorded"}
                </span>
              </p>
            </div>
          )}
        </div>
        <Line className={styles.divider} />
      </div>
      {!checkIsDue && <h2>Check due at {format(checkUpTime, "HH:mmbbb")}</h2>}
      {checkIsDue && (
        <FallCQCCheckForm
          onSubmit={(metaData) => {
            return resolutionHandler(
              fallDetectedModalData,
              metaData,
              "check_completed",
              !!isSecondFollowUp
            );
          }}
        />
      )}
    </NewStyleModal>
  );
};

export default FallCQCCheckModal;
